import empoweringMainLogo from "../../assets/svg/empowering_main_logo.svg";
import empoweringDescLogo from "../../assets/png/empowering_desc_logo.png";
import continuousMainLogo from "../../assets/svg/continuousModernisation.svg";
import cotinuousDescLogo from "../../assets/png/continuous_desc_logo.png";
import leveragingMainLogo from "../../assets/svg/leveragingMainLogo.svg";
import leveragingDescLogo from "../../assets/jpg/leveraging_desc_logo.jpg";
import akscelMainLogo from "../../assets/svg/akscelBlogMainLogo.svg";
import akscelDescriptionLogo from "../../assets/jpg/akscelDescriptionImage.jpg";

export const blogsData = [
  {
    id: 1,
    title: "The Autogenic Low Code Platform for Entrepreneurs | Akscel",
    metaDesc:
      "When you specify your desired requirements, Akscel can facilitate the creation of any application development platform you want, enhancing low-code development.",
    img: akscelMainLogo,
    descImg: akscelDescriptionLogo,
    blogTitle:
      "Akscel - The Autogenic Product for Low-Code Application Development",
    alt: "Akscel is a low code no code platform that enables you to build applications without coding. With Akscel, you can know how to build cloud native applications with no coding",
  },
  {
    id: 2,
    title: "Empowering Entrepreneurs With No-Code Platforms | Akscel",
    metaDesc:
      "Entrepreneurs need not be concerned about the availability of competent developers because Akscel can generate 80% of the software application code",
    img: empoweringMainLogo,
    descImg: empoweringDescLogo,
    blogTitle: "Empowering Entrepreneurs By Automating Application Development",
    alt: "Empowering entrepreneurs with Akscel to build web applications easily without coding",
  },
  {
    id: 3,
    title: "Continuous Modernization: Everything You Need To Know | Akscel",
    metaDesc:
      "Continuous application modernization involves the use of advanced technologies such as cloud computing, microservices, and containerization to transform outdated and rigid software into more flexible, efficient, and scalable forms. ",
    img: leveragingMainLogo,
    descImg: leveragingDescLogo,
    blogTitle:
      "Leveraging The Benefits Of Cloud Native Services Without Facing Vendor Lock-In",
    alt: "How Akscel can you mitigate and prevent cloud vendor lock-in. No vendor lock in cloud issues as Akscel helps you avoid it.",
  },
  {
    id: 4,
    title: "Cloud Vendor Lock-In - What It is and How To Avoid It | Akscel",
    metaDesc:
      "Cloud vendor lock-in, also known as vendor lock-in risk , refers to the situation where organizations become overly dependent on a specific cloud provider's services, making it challenging to switch to alternative platforms.",
    img: continuousMainLogo,
    descImg: cotinuousDescLogo,
    blogTitle:
      "Continuous Modernization: The Changing Face of Modern Application Development",
    alt: "Application modernization solutions like Akscel help organizations to systematically update their legacy applications multiple times ensuring functionality, performance, and maintainability improvements.",
  },
];
