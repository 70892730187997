import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { lazily } from "react-lazily";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { theme } from "./themes/theme";
import { ScopedCssBaseline, ThemeProvider } from "@mui/material";
import { blogsData } from "./pages/blogs/blogsData";
import BlogWrapper from "./wrapper/blogWrapper/BlogWrapper";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

// pages
const {
  Home,
  RequestDemo,
  Blogs,
  SpecificBlog,
  ContinuousModernization,
  AkscelPlatform,
  Contact,
} = lazily(() => import("./pages"));

// layout
const { Header, Footer } = lazily(() => import("./layout"));

export const pageRoutes = {
  home: "/",
  requestDemo: "/requestDemo",
  blogs: "/blogs",
  sepcificBlog: "/blogs/:id",
  continuousModernization: "/continuousModernization",
  dataPlatform: "/dataPlatform",
  applicationPlatform: "/applicationPlatform",
  contact: "/contact",
};

const Layout = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Helmet>
        <title>Build and Deploy Web Applications Without Coding | Akscel</title>
        <meta
          name="description"
          content="Build and deploy cloud-native web applications in minutes with Akscel.No coding required.Choose your language, cloud provider,  and get your app ready to go in no time."
        />
      </Helmet>
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: pageRoutes.home,
    element: <Layout />,
    children: [
      {
        path: pageRoutes.home,
        element: <Home />,
      },
      {
        path: pageRoutes.requestDemo,
        element: <RequestDemo />,
      },
      {
        path: pageRoutes.blogs,
        element: <Blogs />,
      },
      {
        path: pageRoutes.sepcificBlog,
        element: <SpecificBlog />,
      },
      {
        path: pageRoutes.continuousModernization,
        element: (
          <BlogWrapper blogData={blogsData[3]}>
            <ContinuousModernization blogData={blogsData[3]} />
          </BlogWrapper>
        ),
      },
      {
        path: pageRoutes.dataPlatform,
        element: <AkscelPlatform />,
      },
      {
        path: pageRoutes.applicationPlatform,
        element: <AkscelPlatform />,
      },
      {
        path: pageRoutes.contact,
        element: <Contact />,
      },
    ],
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline />
      <Suspense fallback={<div> loading... </div>}>
        <RouterProvider router={router} />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
