import React from "react";
import { useStyles } from "./DownloadBlogs.styles";
import { Box, Button, Typography } from "@mui/material";

// logo
import akLogo from "../../assets/svg/akLogo.svg";

const DownloadBlogs = () => {
  const classes = useStyles();
  return (
    <Box sx={classes.requestDemoBox}>
      <Box sx={classes.requestDemoTextContainer}>
        <Typography sx={classes.requestDemoText}>Download akscelogs</Typography>
        <Typography sx={classes.requestOneCall}>
          Request a one on one call at no cost to get the complete understanding
          of Akscel from our expert.
        </Typography>
        <Button variant="contained" sx={classes.downloadBtn}>
          Download
        </Button>
      </Box>
      <Box sx={classes.akLogo} component="img" src={akLogo} alt="akLogo" />
    </Box>
  );
};

export default DownloadBlogs;
