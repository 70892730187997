import { useTheme } from "@mui/material";
import { colors } from "../../themes/colors";

// export const useStyles = () => {
//   return {
//     requestDemoBox: {
//       background: "#F4F5F5",
//       borderRadius: "80px",
//       margin: useTheme().spacing(10),
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "space-between",
//       padding: useTheme().spacing(6,6 ,6 ,10),
//       [`@media screen and (min-width:0px) and (max-width:480px)`]: {
//         display: "block",
//         margin: useTheme().spacing(10, 2),
//         padding: useTheme().spacing(4),
//       },
//       [`@media screen and (min-width:481px) and (max-width:768px)`]: {
//         margin: useTheme().spacing(10, 4),
//       },
//     },
//     requestDemoTextContainer: {
//       display: "flex",
//       flexDirection: "column",
//       rowGap: useTheme().spacing(3),
//       alignItems: "flex-start",
//       [`@media screen and (min-width:0px) and (max-width:480px)`]: {
//         alignItems: "center",
//         rowGap: useTheme().spacing(2),
//       },
//       [`@media screen and (min-width:481px) and (max-width:768px)`]: {
//         rowGap: useTheme().spacing(2),
//       },
//     },
//     requestDemoText: {
//       "&.MuiTypography-root": {
//         fontFamily: "M PLUS 1",
//         fontWeight: 600,
//         fontSize: "40px",
//         lineHeight: "55px",
//         letterSpacing: "0.01em",
//         textAlign: "left",
//         color: "#313638",
//         [`@media screen and (min-width:0px) and (max-width:768px)`]: {
//           lineHeight: "43px",
//           fontSize: "30px",
//         },
//       },
//     },
//     requestOneCall: {
//       "&.MuiTypography-root": {
//         fontFamily: "M PLUS 1",
//         fontWeight: 400,
//         fontSize: "18px",
//         letterSpacing: "0.01em",
//         textAlign: "left",
//         color: "#313638",
//         width: "340px",
//         [`@media screen and (min-width:0px) and (max-width:360px)`]: {
//           width: "235px",
//           textAlign: "center",
//         },
//         [`@media screen and (min-width:361px) and (max-width:480px)`]: {
//           fontSize: "16px",
//           width: "300px",
//           textAlign: "center",
//         },
//         [`@media screen and (min-width:481px) and (max-width:768px)`]: {
//           fontSize: "15px",
//           width: "260px",
//         },
//       },
//     },
//     downloadBtn: {
//       "&.MuiButton-contained": {
//         borderRadius: "30px",
//         fontWeight: 400,
//         fontSize: "20px",
//         lineHeight: "20px",
//         background: "#45E555",
//         padding: useTheme().spacing(3.5,4.5),
//         color: colors.black,
//         marginTop: useTheme().spacing(3),
//         "&:hover": {
//           background: "#45E555",
//         },
//       },
//     },
//     akLogo: {
//       objectFit: "cover",
//       width: "45%",
//       [`@media screen and (min-width:0px) and (max-width:480px)`]: {
//         display: "none",
//       },
//       [`@media screen and (min-width:481px) and (max-width:768px)`]: {
//         width: "60%",
//       },
//     },
//   };
// };

export const useStyles = () => {
  return {
    requestDemoBox: {
      background: "#F4F5F5",
      borderRadius: "80px",
      margin: useTheme().spacing(10),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: useTheme().spacing(6, 6, 6, 10),
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        display: "block",
        borderRadius: "30px",
        margin: useTheme().spacing(10, 2),
        padding: useTheme().spacing(6, 4),
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        margin: useTheme().spacing(10, 4),
        padding: useTheme().spacing(6),
      },
    },
    requestDemoTextContainer: {
      display: "flex",
      flexDirection: "column",
      rowGap: useTheme().spacing(3),
      alignItems: "flex-start",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        alignItems: "center",
        rowGap: useTheme().spacing(2),
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        rowGap: useTheme().spacing(2),
      },
    },
    requestDemoText: {
      "&.MuiTypography-root": {
        fontFamily: "M PLUS 1",
        fontWeight: 600,
        fontSize: "60px",
        lineHeight: "86.88px",
        letterSpacing: "0.01em",
        textAlign: "left",
        color: "#313638",
        [`@media screen and (min-width:0px) and (max-width:480px)`]: {
          textAlign: "center",
        },
        [`@media screen and (min-width:0px) and (max-width:768px)`]: {
          lineHeight: "43px",
          fontSize: "30px",
        },
      },
    },
    requestOneCall: {
      "&.MuiTypography-root": {
        fontFamily: "M PLUS 1",
        fontWeight: 400,
        fontSize: "18px",
        letterSpacing: "0.01em",
        textAlign: "left",
        color: "#313638",
        width: "340px",
        [`@media screen and (min-width:0px) and (max-width:360px)`]: {
          width: "unset",
          fontSize: "16px",
          textAlign: "center",
        },
        [`@media screen and (min-width:361px) and (max-width:480px)`]: {
          fontSize: "16px",
          width: "300px",
          textAlign: "center",
        },
        [`@media screen and (min-width:481px) and (max-width:768px)`]: {
          fontSize: "15px",
          width: "260px",
        },
      },
    },
    downloadBtn: {
      "&.MuiButton-contained": {
        borderRadius: "30px",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "20px",
        background: "#45E555",
        padding: useTheme().spacing(4, 5),
        marginTop: useTheme().spacing(2),
        color: colors.black,
        "&:hover": {
          background: "#45E555",
        },
        [`@media screen and (min-width:0) and (max-width:768px)`]: {
          padding: useTheme().spacing(3, 4),
          fontSize: "18px",
          order: 3,
        },
      },
    },
    akLogo: {
      objectFit: "cover",
      width: "45%",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        display: "none",
      },
      [`@media screen and (min-width:481px) and (max-width:768px)`]: {
        width: "50%",
      },
    },
  };
};
