import React from "react";
import { useStyles } from "./blogWrapper.styles";
import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import DownloadBlogs from "../../components/blogs/DownloadBlogs";

const BlogWrapper = ({ blogData, children }) => {
  const classes = useStyles();
  return (
    <>
      <Box sx={classes.mainContainer}>
        <Helmet>
          <title>{blogData?.title}</title>
          <meta name="description" content={blogData?.metaDesc} />
        </Helmet>
        <Grid container sx={classes.container} spacing={6}>
          <Grid item xl={12}>
            <Typography sx={classes.heading}>{blogData?.blogTitle}</Typography>
          </Grid>

          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            textAlign={"center"}
          >
            <Box
              component="img"
              sx={classes.blogImage}
              src={blogData?.img}
              alt={blogData?.alt}
            />
          </Grid>
          {children}
        </Grid>
      </Box>
      <DownloadBlogs />
    </>
  );
};

export default BlogWrapper;
