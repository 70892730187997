import { useTheme } from "@mui/material";
import { colors } from "../../themes/colors";

export const useStyles = () => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "#f5f5f9",
    },
    container: {
      "&.MuiGrid-container": {
        display: "flex",
        flexDirection: "column",
        marginTop: useTheme().spacing(10),
        padding: useTheme().spacing(8, 15, 20, 15),
        width: "75%",
        [`@media screen and (min-width:0px) and (max-width:480px)`]: {
          marginTop: useTheme().spacing(2),
          padding: useTheme().spacing(8, 0, 10, 0),
          width: "100%",
        },
        [`@media screen and (min-width:480px) and (max-width:768px)`]: {
          marginTop: useTheme().spacing(6),
          padding: useTheme().spacing(8, 4, 15, 4),
          width: "100%",
        },
      },
    },
    heading: {
      "&.MuiTypography-root": {
        fontFamily: "M PLUS 1",
        fontWeight: 600,
        fontSize: "44px",
        textAlign: "left",
        color: "#000000",
        letterSpacing: "0.01em",
        "&:first-line": {
          color: colors.secondary.color7,
        },
        [`@media screen and (min-width:0px) and (max-width:360px)`]: {
          fontSize: "24px",
        },
        [`@media screen and (min-width:361px) and (max-width:768px)`]: {
          fontSize: "32px",
        },
      },
    },

    blogImage: {
      borderRadius: useTheme().spacing(8),
      width: "474px",
      height: "360px",
      [`@media screen and (min-width:0px) and (max-width:480px)`]: {
        borderRadius: useTheme().spacing(2),
        height: "auto",
        width: "100%",
      },
      [`@media screen and (min-width:480px) and (max-width:768px)`]: {
        height: "460px",
      },
    },
  };
};
