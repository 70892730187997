export const colors = {
  primary: {
    color1: "#101B39",
    color2: "#0D2565",
    color3: "#1976d2",
    color4: "#DFE8FF",
    color5: "#154AD1",
    color6: "",
    lightBlack: "#0E1218",
    lightGrey: "#F0F0F0",
  },
  secondary: {
    color1: "#888888",
    color2: "#f0f2f8",
    color3: "#D9D9D9",
    color4: "#323232",
    color5: "#F5F5F5",
    color6: "#A1A6B6",
    color7: "#45E555",
    green: "#15B525",
    darkGreen: "#197322",
    lightGreenBg: "#F0F2F5",
  },
  tertiary: {
    color1: "#43A047",
    color2: "#000000",
    color3: "#ffffff",
    color4: "#1A9F20",
    color5: "#FFAC0A",
    color6: "#E84F4F",
    color7: "#D32F2F",
    color8: "#DBDFE3",
    color9: "#4CA730",
    error: "#F41818",
    muiError: "#d32f2f",
  },
  white: "#ffffff",
  black: "#000000",
  lightGreenBg: "#EEF2F5",
};
